export default function() {
    const mapEl = document.querySelector('[data-map-holder]') as HTMLElement
    if (!mapEl) return

    const mapCenter = {lat: 49.834985, lng: 18.281703}

    const map = new google.maps.Map(mapEl, {
        center: mapCenter,
        zoom: 18,
        gestureHandling: "greedy",
        styles: [
            {
                "featureType": "administrative",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "color": "#fcfcfc"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "hue": "#0061ff"
                    },
                    {
                        "saturation": "-41"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "color": "#fcfcfc"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "-100"
                    },
                    {
                        "lightness": "25"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "color": "#dddddd"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#dddddd"
                    },
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "weight": "1.03"
                    },
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "color": "#eeeeee"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "gamma": "1.08"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "geometry",
                "stylers": [
                    {
                        "lightness": "64"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": "-100"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "visibility": "simplified"
                    },
                    {
                        "color": "#c9dbe4"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            }
        ]
    });

    const buildingCoordinates = [
        { lat: 49.835147, lng: 18.282439 },
        { lat: 49.835046, lng: 18.282153 },
        { lat: 49.834937, lng: 18.281945 },
        { lat: 49.834826, lng: 18.281771 },
        { lat: 49.835119999561485, lng: 18.281563666743978 },
        { lat: 49.83503304001513, lng: 18.28121601795554 },
        { lat: 49.834818, lng: 18.281353 },
        { lat: 49.834792, lng: 18.281271 },
        { lat: 49.834725, lng: 18.281306 },
        { lat: 49.834666, lng: 18.281182 },
        { lat: 49.835090, lng: 18.280904 },
        { lat: 49.83527996430279, lng: 18.281699018595983 },
        { lat: 49.83506890498477, lng: 18.281851904509832 },
        { lat: 49.83517156717775, lng: 18.28219946516097 },
        { lat: 49.83537235952685, lng: 18.282049705123 },
        { lat: 49.835424, lng: 18.282240 },
        { lat: 49.835147, lng: 18.282439 }
    ];

    const buildingOutline = new google.maps.Polyline({
        path: buildingCoordinates,
        geodesic: true,
        strokeColor: "#1e4994",
        strokeOpacity: 1.0,
        strokeWeight: 2,
    });

    (new google.maps.Polyline({
        path: [
            { lat: 49.834964120467035, lng: 18.281674028131565 },
            { lat: 49.83486889052538, lng: 18.28132127051301 }
        ],
        geodesic: true,
        strokeColor: "#00A37C",
        strokeOpacity: 1.0,
        strokeWeight: 2,
    })).setMap(map);

    (new google.maps.Polyline({
        path: [
            { lat: 49.835255483280726, lng: 18.281717802855013 },
            { lat: 49.83535107625899, lng: 18.282066855597762 }
        ],
        geodesic: true,
        strokeColor: "#00A37C",
        strokeOpacity: 1.0,
        strokeWeight: 2,
    })).setMap(map);

    buildingOutline.setMap(map);

    map.addListener("tilesloaded", () => mapEl.classList.remove('is-loading'))
    //map.addListener("click", (mapsMouseEvent) => console.log(mapsMouseEvent.latLng.toJSON()));
}